<template>
  <v-container fluid py-0>
    <v-form ref="form" class="py-3" lazy-validation style="max-width: 600px">
      <v-card class="sweeep-dialog">
        <v-card-title>
          {{ $t('message.line_use') }}
        </v-card-title>
        <v-card-text class="pa-4 justify-center">
          <v-layout row wrap>
            <v-flex xs12 class="px-3">
              <div class="fs-14 mb-3 fw-bold">1. LINEアカウントの登録</div>
              <div class="fs-12 mb-3 px-3">
                次のボタンを押してLINE内にsweeepLINE公式アカウントを登録します。
              </div>
              <a class="mb-2" :href="envToSrc[env]" target="blank">
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                  height="36"
                  border="0"
                />
              </a>
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">2. アクセストークンの有効化</div>
              <div class="fs-12 mt-3 px-3">
                2-1.
                LINE公式アカウント登録後にLINEにてアクセストークンが発行されます。
              </div>
              <div class="fs-12 px-3">
                2-2.
                LINEにて発行されたトークンを以下に入力し認証ボタンを押してください。
              </div>
              <v-text-field
                id="tokenfield"
                v-model="lineToken"
                class="px-0 mt-2 mb-0"
                label="アクセストークンを入力"
                required
                outlined
                dense
                filled
              />
              <v-btn class="mb-3" color="primary" @click="postLineInfo()">
                {{ $t('message.line_ok') }}
              </v-btn>
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">連携の解除</div>
              <div class="fs-12 my-3 px-3">
                連携を解除したい場合は次のボタンを押してください。
              </div>
              <v-btn class="mb-2" color="" @click="showLineModal()">
                {{ $t('message.disconnect') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-form>

    <base-dialog
      ref="dialogDeleteLine"
      :title="$t('message.confirm')"
      message="LINEとの提携を解除してもよろしいですか？"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="
        disconnectLine()
        $refs.dialogDeleteLine.close()
      "
    />
  </v-container>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import Axios from 'axios'

export default {
  data() {
    return {
      lineToken: '',
      disabled: {
        newAuthorization: false,
      },
      env: process.env.VUE_APP_ENV_TYPE,
      envToSrc: {
        local: 'https://lin.ee/Y0w02Yo',
        dev02: 'https://lin.ee/qc5ibtB',
        cs_test: 'https://lin.ee/HyaaKVG',
        staging: 'https://lin.ee/cyOoFsI',
        production: 'https://lin.ee/4GyjgM5',
      },
    }
  },
  created() {
    try {
      Axios.get(this.$store.getters.apiLineAuth, {
        headers: { Authorization: this.$store.getters.getAuthToken },
        params: { user_org_id: this.$store.getters.getUserOrganizationID },
      }).then((response) => {
        if (response.data.exists == 1)
          this.lineToken = 'アカウント認証済みです。'
      })
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    postLineInfo() {
      //connect line endpoint
      try {
        let user = JSON.parse(localStorage.getItem('user'))
        let user_input = this.lineToken //get line access token from browser
        let url = this.$store.getters.apiLineAuth ///linecheck
        Axios.post(
          url,
          {
            access_token: user_input,
            user_org_id: user.user_organization_id,
            org_id: user.organization_id,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          let result = response.data.result
          let textArea = document.getElementById('tokenfield')
          if (result == 'Success') {
            Snackbar.success('成功しました。')
            textArea.value = '認証に成功しました！'
            let isLineAuthorized = true
            localStorage.setItem('isLineAutorized', isLineAuthorized)
          } else if (result == 'DetectMultipleToken') {
            textArea.value = 'このトークンは既に登録済みです。'
          } else if (result == 'AccessTokenInvaild') {
            textArea.value = 'トークンの値が間違っています。'
          }
          //cancel ボタンを押した時の関数も設定した方が良いと思われる
        })
      } catch (e) {
        console.log(e)
      }
    },
    showLineModal() {
      this.$refs.dialogDeleteLine.open()
    },
    disconnectLine() {
      try {
        Axios.delete(this.$store.getters.apiLineAuth, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { user_org_id: this.$store.getters.getUserOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.lineToken = ''
            Snackbar.success('成功しました。')
          }
        })
      } catch (err) {
        console.log(err)
        Snackbar.error('エラーが発生しました。')
      }
    },
  },
}
</script>
