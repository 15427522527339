<template>
  <v-container fluid py-0>
    <v-form ref="form" class="py-3" lazy-validation style="max-width: 600px">
      <v-card class="sweeep-dialog">
        <v-card-title>{{ $t('message.Chatwork_use') }}</v-card-title>
        <v-card-text class="pa-4 justify-center">
          <div
            v-if="chatworkToken.getChatworkToken == true"
            class="text-xs-center"
          >
            <v-progress-circular indeterminate color="primary" />
          </div>
          <v-layout v-else row wrap>
            <v-flex xs12 class="px-3">
              <div class="fs-14 mb-3 fw-bold">1. コンタクト追加</div>
              <div class="fs-12 px-3">
                1-1. Chatworkの画面でsweeepユーザ（Chatwork ID:
                Sweeep）を追加します。
              </div>
              <div class="fs-12 mb-2 px-3">
                1-2.
                Chatwork上でコンタクトを追加したら次のボタンをクリックします。
              </div>
              <v-btn class="mb-2" color="primary" @click="ApproveContact()">
                コンタクト承認
              </v-btn>
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">2. アクセストークンの有効化</div>
              <div class="fs-12 my-3 px-3">
                次のボタンを押してアクセストークンを発行します。発行されたアクセストークン（token:
                を含む文字）をコピーし、Chatwork内のsweeepユーザにダイレクトメッセージ送ってください。
              </div>
              <v-btn
                class="mb-2"
                color="primary"
                :loading="chatworkToken.allowChatworkUpload"
                @click="createChatworkToken()"
              >
                {{ $t('message.Chatwork_issue') }}
              </v-btn>
              <v-text-field
                class="px-0 mt-2 mb-0"
                label=""
                :value="chatworkToken.token"
                append-icon="zmdi-copy"
                outlined
                dense
                filled
                @click.stop.prevent="writeToClipboard()"
              />
              <input
                id="chatwork_id"
                type="hidden"
                :value="chatworkToken.token"
              />
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">連携の解除</div>
              <div class="fs-12 my-3 px-3">
                連携を解除したい場合は次のボタンを押してください。
              </div>
              <v-btn class="mb-2" @click="showModal()">
                {{ $t('message.disconnect') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-form>

    <base-dialog
      ref="dialogDeleteChatwork"
      :title="$t('message.confirm')"
      message="Chatworkとの提携を解除してもよろしいですか？"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="
        disconnectChatwork()
        $refs.dialogDeleteChatwork.close()
      "
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="success"
      class="mt-4"
      top
    >
      <p class="ma-0"><i class="ti-thumb-up mr-2" />{{ copiedMessage }}</p>
    </v-snackbar>
  </v-container>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import Axios from 'axios'

export default {
  data() {
    return {
      copiedMessage: '',
      timeout: 2000,
      snackbar: false,
      chatworkToken: {
        allowChatworkUpload: false,
        getChatworkToken: false,
        token: '',
      },
    }
  },
  mounted() {
    this.getChatworkToken()
  },
  methods: {
    writeToClipboard() {
      let chatworkToCopy = document.getElementById('chatwork_id')
      chatworkToCopy.setAttribute('type', 'text')
      chatworkToCopy.select()

      try {
        var successful = document.execCommand('copy')
        this.copiedMessage = successful
          ? 'トークンをコピーしました'
          : 'トークンをコピーできませんでした。'
        this.snackbar = true
      } catch (err) {
        this.copiedMessage = 'トークンをコピーできませんでした。'
        console.log(err)
      }
      chatworkToCopy.setAttribute('type', 'hidden')
    },
    getChatworkToken() {
      try {
        Axios.get(this.$store.getters.apiChatworkAuth, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { user_org_id: this.$store.getters.getUserID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.chatworkToken.token = 'token:' + response.data.token
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    createChatworkToken() {
      try {
        let user = JSON.parse(localStorage.getItem('user'))
        Axios.post(
          this.$store.getters.apiChatworkAuth,
          {
            user_org_id: user.user_organization_id,
            org_id: user.organization_id,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          if (response.data.token) {
            this.chatworkToken.token = response.data.token
            Snackbar.success('認証成功')
          } else {
            Snackbar.error('すでにアカウント認証済みです。')
          }
        })
      } catch (e) {
        console.log(e)
        Snackbar.error('アカウント認証エラー')
      }
    },
    ApproveContact() {
      Axios.put(
        this.$store.getters.apiChatworkAuth,
        {},
        { headers: { Authorization: this.$store.getters.getAuthToken } }
      ).then((response) => {
        if (response.data.result) {
          Snackbar.error('コンタクト承認依頼が届いていません')
        } else {
          Snackbar.success('成功')
        }
      })
    },
    disconnectChatwork() {
      //modal出したい
      try {
        Axios.delete(this.$store.getters.apiChatworkAuth, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { user_org_id: this.$store.getters.getUserOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.chatworkToken.token = ''
            Snackbar.success('成功しました。')
          }
        })
      } catch (err) {
        console.log(err)
        Snackbar.error('エラーが発生しました。')
      }
    },
    showModal() {
      this.$refs.dialogDeleteChatwork.open()
    },
  },
}
</script>
