<template>
  <v-container fluid py-0>
    <v-form ref="form" class="py-4" lazy-validation style="max-width: 600px">
      <v-card class="sweeep-dialog">
        <v-card-title>
          {{ $t('message.slack_use') }}
        </v-card-title>
        <v-card-text class="pa-4 justify-center">
          <div v-if="slackToken.getSlackToken == true" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <v-layout v-else row wrap>
            <v-flex xs12 class="px-3">
              <div class="fs-14 mb-3 fw-bold">1. SlackにBotを作成</div>
              <div class="fs-12 mb-3 px-3">
                次のボタンを押してSlackにsweeepのBotを追加します。
              </div>
              <button-add-to-slack />
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">2. アクセストークンの有効化</div>
              <div class="fs-12 my-3 px-3">
                次のボタンを押してアクセストークンを発行します。
                <br />発行されたアクセストークン（
                "token:"を含む文字）をコピーし、Slack内のBotにダイレクトメッセージ送ってください。
              </div>
              <v-btn
                class="mb-2"
                color="primary"
                :loading="slackToken.allowSlackUpload"
                @click="createSlackToken()"
              >
                {{ $t('message.Chatwork_issue') }}
              </v-btn>
              <v-text-field
                class="px-0 mt-2 mb-0"
                label
                :value="slackToken.token"
                append-icon="zmdi-copy"
                outlined
                dense
                filled
                @click.stop.prevent="writeToClipboard()"
              />
              <input id="slack_id" v-model="slackToken.token" type="hidden" />
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">3. 請求書の連携</div>
              <div class="fs-12 my-3 px-3">
                Slackに作成したBotに請求書をアップ（ダイレクトメッセージ）するとsweeepへ連携されます。
              </div>
              <v-divider />

              <v-layout>
                <v-flex xs3>
                  <div class="fs-14 mt-3 fw-bold">通知設定</div>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox
                    v-model="sendNotify"
                    label="Botからの通知を許可する"
                    color="primary"
                  />
                </v-flex>
              </v-layout>
              <v-autocomplete
                v-if="sendNotify"
                v-model="channelToSend"
                v-tooltip="$t('message.slack_hint_channel_list')"
                :items="channels"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                filled
                @input="postChannel()"
              />
              <v-divider />

              <div class="fs-14 mt-3 fw-bold">連携の解除</div>
              <div class="fs-12 my-3 px-3">
                連携を解除したい場合は次のボタンを押してください。
              </div>
              <v-btn class="mb-2" color @click="showModal">
                {{ $t('message.disconnect') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="success"
      class="mt-4"
      top
    >
      <p class="ma-0">
        <i class="ti-thumb-up mr-2" />
        {{ copiedMessage }}
      </p>
    </v-snackbar>

    <base-dialog
      ref="dialogDeleteToken"
      :title="$t('message.confirm')"
      message="Slackとの提携を解除してもよろしいですか？"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="
        disconnectSlack()
        $refs.dialogDeleteToken.close()
      "
    />
  </v-container>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import ButtonAddToSlack from './ButtonAddToSlack'
import Axios from 'axios'

export default {
  components: {
    ButtonAddToSlack,
  },
  data() {
    return {
      copiedMessage: '',
      snackbar: false,
      slackToken: {
        allowSlackUpload: false,
        getSlackToken: false,
        token: '',
      },
      sendNotify: false,
      channelToSend: null,
      channels: [],
      timeout: 2000,
    }
  },
  watch: {
    sendNotify: function (boolean) {
      if (boolean) {
        this.getChannels()
      } else if (!boolean && !!this.channelToSend) {
        this.channelToSend = null
        this.postChannel()
      }
    },
  },
  mounted() {
    this.getSlackToken()
  },
  methods: {
    writeToClipboard() {
      let slackTokenToCopy = document.querySelector('#slack_id')
      slackTokenToCopy.setAttribute('type', 'text')
      slackTokenToCopy.select()
      try {
        var successful = document.execCommand('copy')
        this.copiedMessage = successful
          ? 'トークンをコピーしました'
          : 'トークンをコピーできませんでした。'
        this.snackbar = true
      } catch (err) {
        this.copiedMessage = 'トークンをコピーできませんでした。'
      }
      slackTokenToCopy.setAttribute('type', 'hidden')
    },
    getSlackToken() {
      try {
        Axios.get(this.$store.getters.apiSlackAuth, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { user_org_id: this.$store.getters.getUserOrganizationID },
        }).then((response) => {
          if (response.data.token) {
            this.slackToken.token = 'token:' + response.data.token
            this.channelToSend = response.data.channel
            if (this.channelToSend) {
              this.sendNotify = true
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    createSlackToken() {
      try {
        let user = JSON.parse(localStorage.getItem('user'))
        Axios.post(
          this.$store.getters.apiSlackAuth,
          {
            user_org_id: user.user_organization_id,
            org_id: user.organization_id,
          },
          {
            headers: { Authorization: this.$store.getters.getAuthToken },
          }
        ).then((response) => {
          if (response.data.token) {
            this.slackToken.token = response.data.token
            Snackbar.success('認証成功')
          } else {
            Snackbar.error('すでにアカウント認証済みです。')
          }
        })
      } catch (e) {
        Snackbar.error('アカウント認証エラー')
      }
    },
    disconnectSlack() {
      try {
        Axios.delete(this.$store.getters.apiSlackAuth, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { user_org_id: this.$store.getters.getUserOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.slackToken.token = ''
            Snackbar.success('成功しました。')
          }
        })
      } catch (err) {
        console.log(err)
        Snackbar.error('エラーが発生しました。')
      }
    },
    showModal() {
      this.$refs.dialogDeleteToken.open()
    },
    getChannels() {
      try {
        const url = this.$store.getters.apiSlackChannels
        Axios.get(url, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: {
            user_organization_id: this.$store.getters.getUserOrganizationID,
          },
        }).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
            this.sendNotify = false
          } else {
            this.channels = response.data.channels
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    postChannel() {
      try {
        const url = this.$store.getters.apiSlackChannels
        const headers = this.$store.getters.getRequestHeaders
        const data = {
          user_organization_id: this.$store.getters.getUserOrganizationID,
          channel: this.channelToSend,
        }
        Axios.post(url, data, headers).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            if (!!this.channelToSend) {
              Snackbar.success('変更に成功しました。')
            } else {
              Snackbar.success('通知を解除しました')
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
