<template>
  <v-container class="pa-0 ma-0 pl-3 mt-2">
    <template>
      <a :href="envToSrc[env]">
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcset="
            https://platform.slack-edge.com/img/add_to_slack.png    1x,
            https://platform.slack-edge.com/img/add_to_slack@2x.png 2x
          "
        />
      </a>
    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // eslint-disable-next-line
      env: process.env.VUE_APP_ENV_TYPE,
      envToSrc: {
        local:
          'https://slack.com/oauth/v2/authorize?client_id=359829268768.1324405126548&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.public,files:read,groups:read,im:history,im:read,incoming-webhook,mpim:read&user_scope=',
        dev02:
          'https://slack.com/oauth/v2/authorize?client_id=359829268768.1469348189587&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.public,commands,files:read,groups:read,im:history,im:read,mpim:read&user_scope=im:read',
        cs_test:
          'https://slack.com/oauth/v2/authorize?client_id=359829268768.2075719807632&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.public,files:read,groups:read,im:history,im:read,mpim:read&user_scope=',
        staging:
          'https://slack.com/oauth/v2/authorize?client_id=359829268768.904961700789&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.public,commands,files:read,im:history,im:read,mpim:read,groups:read',
        production:
          'https://slack.com/oauth/v2/authorize?client_id=359829268768.1045862285463&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.public,commands,files:read,groups:read,im:history,im:read,mpim:read',
      },
    }
  },
}
</script>
